body {
	min-width: 1240px;
}
.cont {
	padding: 0px 160px;
	margin: 0px auto;
	align-items: center;
	justify-content: center;
}
/** Header **/
header {
	background-color: #5c5c5c;
	justify-content: center;
}
.contact {
	font-family: Montserrate;
	font-weight: 600;
	margin-right: 120px;
	letter-spacing: 1px;
	font-style: normal;
	line-height: normal;
	color: white;
}
.anim {
	text-decoration: none;
	background-image: linear-gradient(currentColor, currentColor);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 0% 2px;
	transition: background-size 0.3s;
}
.anim:hover {
	background-size: 100% 2px;
	color: white;
}
@font-face {
	font-family: Montserrate;
	src: url(../fonts/MontserratAlternates-SemiBold.ttf);
}

@font-face {
	font-family: Jost;
	src: url(../fonts/Jost-VariableFont_wght.ttf);
}

@media (min-width: 320px) {
	.logo {
		width: 300px;
		height: 60px;
		margin-left: 60px;
	}
	.title {
		left: 33.7%;
	}
	.title2 {
		left: 23.3%;
		bottom: 2%;
	}
	.technic {
		margin: 0 145px;
	}
	.text-about1 {
		margin: 30px 0 20px -60px;
	}
	.about1 {
		height: 380px;
		width: 280px;
		margin-top: 20px;
		margin-left: 200px;
	}
	.text-about2 {
		margin: 20px 10px 0px 50px;
	}
	.about2 {
		height: 330px;
		width: 320px;
		margin-top: 20px;
		margin-left: -60px;
	}
	.partners {
		width: 80%;
	}
	.cards {
		padding: 20px;
		margin-top: 80px;
	}
}

@media (min-width: 480px) {
	.logo {
		width: 300px;
		height: 60px;
	}
	.title {
		left: 38.5%;
	}
	.title2 {
		left: 23.5%;
		bottom: 5%;
	}
	.text-about1 {
		margin: 10px 0px 20px 30px;
	}
	.about1 {
		margin-top: 30px;
	}
	.text-about2 {
		margin: 20px 0px 0px 50px;
	}
	.about2 {
		margin-left: 20px;
	}
	.partners {
		width: 80%;
	}
}
@media (min-width: 600px) {
	.title {
		left: 34.6%;
	}
	.title2 {
		left: 23.5%;
		bottom: 5%;
	}
	.technic {
		margin: 0 150px;
	}
	.text-about1 {
		margin: 10px 0px 20px 30px;
	}
	.about1 {
		margin-top: 100px;
	}
	.text-about2 {
		margin: 50px 0px 0px 50px;
	}
	.partners {
		width: 80%;
	}
}
@media (min-width: 1025px) {
	.logo {
		margin-left: 105px;
	}
	.title {
		left: 38.6%;
	}
	.title2 {
		left: 27.5%;
		bottom: 3%;
	}
	.technic {
		margin: 0 250px;
	}
	.text-about1 {
		margin: 50px 0px 30px 50px;
	}
	.about1 {
		height: 270px;
		width: 270px;
		margin-top: 50px;
		margin-left: 180px;
		margin-right: 43px;
	}
	.text-about2 {
		margin: 35px 50px 0px 100px;
	}
	.about2 {
		height: 350px;
		width: 320px;
		margin-top: 30px;
		margin-left: 50px;
	}
	.partners {
		width: 70%;
	}
	.cards {
		padding: 50px;
		margin-top: 40px;
	}
}
@media (min-width: 1920px) {
	.title {
		left: 44.5%;
	}
	.title2 {
		left: 32.5%;
		bottom: 12.5%;
	}
	.text-about1 {
		margin: 50px 0px 30px 275px;
	}
	.about1 {
		margin-top: 50px;
		margin-left: 125px;
	}
	.about2 {
		margin-top: 30px;
		margin-left: 275px;
	}
	.text-about2 {
		margin: 40px 250px 0px 90px;
	}
	.partners {
		width: 45%;
	}
	.partner {
		height: 72px;
	}
	.cards {
		padding: 50px;
		margin-top: 48px;
	}
}
@media (min-width: 2000px) {
	.title {
		left: 46.5%;
	}
	.title2 {
		left: 34.5%;
		bottom: 2%;
	}
	.text-about1 {
		margin: 50px 0px 30px 375px;
	}
	.about1 {
		margin-top: 50px;
		margin-left: 125px;
	}
	.about2 {
		margin-top: 30px;
		margin-left: 375px;
	}
	.text-about2 {
		margin: 40px 250px 0px 90px;
	}
	.cards {
		padding: 70px;
		margin-top: 18px;
	}
}
@media (min-width: 2200px) {
	.title {
		left: 48.5%;
	}
	.title2 {
		left: 36.2%;
		bottom: 2%;
	}
	.text-about1 {
		margin: 50px 0px 30px 505px;
	}
	.about1 {
		margin-top: 50px;
		margin-left: 132px;
	}
	.about2 {
		margin-top: 30px;
		margin-left: 505px;
	}
	.text-about2 {
		margin: 40px 250px 0px 90px;
	}
	.cards {
		padding: 100px;
		margin-top: 18px;
	}
}
@media (min-width: 2400px) {
	.cards {
		padding: 100px;
		margin-top: -20px;
	}
}
@media (min-width: 2600px) {
	.cards {
		padding: 120px;
		margin-top: -40px;
	}
}
@media (min-width: 2800px) {
	.cards {
		padding: 160px;
		margin-top: -80px;
	}
}
@media (min-width: 3000px) {
	.cards {
		padding: 280px;
		margin-top: -200px;
	}
}
/** Slider **/
.slidercard {
	width: 1240px;
}
.slick-list {
	overflow: hidden;
}
.slick-track {
	display: -webkit-box;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.slick-dots {
	display: -webkit-box;
	display: flex;
	align-items: center;
	justify-content: center;
}
.slick-dots li {
	list-style: none;
	margin: 0px 10px;
}
.slick-dots li button {
	font-size: 0;
	border: none;
	width: 15px;
	height: 15px;
	background-color: #d9d9d9;
	border-radius: 50%;
	user-select: none;
}
.slick-dots li.slick-active button {
	background-color: #ffba00;
}
.slick-dots li button:before {
	font-size: 0px;
}
.bg1 {
	background-image: url(../img/bg1.png);
	width: 100%;
	height: 720px;
	background-size: cover;
	background-position: center;
}
.bg2 {
	background-image: url(../img/bg2.png);
	width: 100%;
	height: 720px;
	background-size: cover;
	background-position: center;
}
.text {
	color: #ffba00;
}
.title {
	position: absolute;
	top: 50%;
	transform: translate(-40%, -40%);
	color: #ffffff;
	font-family: Jost;
	font-size: 20px;
	/* text-align: left; */
}
.title2 {
	position: absolute;
	margin-top: 50px;
	text-align: right;
	color: #ffffff;
	font-family: Jost;
	font-size: 20px;
}
.mainpagephoto {
	display: block;
	margin: 0 auto;
	height: 620px;
}
.mainpagephoto2 {
	display: block;
	margin: 0 auto;
	height: 600px;
}
img {
	pointer-events: none;
	user-select: none;
}
/* technic */
.technic {
	display: block;
	height: 233px;
}
/** About1 **/
.about {
	margin-top: 70px;
	width: 650px;
	height: 70px;
}
.about-text {
	/* font-family: Jost; */
	word-wrap: break-word;
}
.first-about,
.second-about {
	/* font-family: Jost; */
	display: flex;
	font-size: 22px;
}
.text-bold {
	font-weight: 600;
	font-size: 28px;
}
.partner {
	width: 100%;
}
.partners {
	display: block;
	margin: 0 auto;
}
/** Product Card + slider **/
.bg3 {
	background-image: url(../img/bg3.svg);
	height: 720px;
	background-size: cover;
}
/* Product Card */
.cart {
	border: 1px rgba(92, 92, 92, 0.7) solid;
	background: rgba(217, 217, 217, 0);
	transform-origin: 0 0;
	border-radius: 10px;
	border-width: 1px;
	width: 280px;
	height: 410px;
	text-align: center;
	margin: 10px;
}
.btns {
	/* font-family: Jost; */
	color: #ffffff;
}
/* About2 */
.skolkovo-text1 {
	margin-top: 20px;
	font-size: 22px;
	margin-left: 50px;
}
.skolkovo-text2 {
	font-size: 22px;
	margin-left: 10px;
}
.skolkovo-text3 {
	font-size: 22px;
	margin-right: 130px;
	margin-left: -39px;
}
.skolkovo-text4 {
	font-size: 22px;
	margin-right: 90px;
	padding-top: 20px;
}
.sk {
	width: 350px;
	height: 250px;
}
.fasie {
	margin-top: 30px;
	width: 260px;
	height: 150px;
}
.patent {
	width: 200px;
	height: 300px;
}
/* Footer */
.bg4 {
	background-color: #5c5c5c;
}

.footer1 {
	font-size: 40px;
	color: #ffffff;
	display: block;
	font-weight: 600;
	text-align: center;
}
.footer2 {
	font-size: 15px;
	text-align: center;
	color: #ffffff;
}

#container {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
#main-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

#block1 {
	margin-right: 60px;
}

#block2 {
	margin-left: 60px;
}
.SocialNetworksIcons {
	text-align: center;
}

.phone {
	text-decoration: none;
}
.text-end {
	justify-content: space-between;
}
