@media (min-width: 320px) {
	.modale__content {
		width: 80%;
	}
}
@media (min-width: 1240px) {
	.modale__content {
		width: 60%;
	}
}
.modale {
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: 0.5s;
}

.modale.active {
	opacity: 1;
	pointer-events: all;
}
.modale__content {
	padding: 10px;
	border-radius: 12px;
	background-color: #ffffff;
	transform: scale(0.5);
	transition: 0.4s all;
}
.modale__content.active {
	transform: scale(1);
}
.img {
	width: 60%;
}
